<template>
  <div id="notice" class="mt-4">
    <h4>Choose a state to get started!</h4>
    <p>
      Click a state to start counting the votes! Remember, you may or may not win this
      election, but you can guarantee victory if you don't count all the votes! Remember,
      you need 270 electoral college votes to win the election! Don't worry if your
      opponent gets more votes than you, you can always just sue the state!
    </p>
  </div>
</template>

<script>
export default {
  name: 'Notice',
};
</script>
