<template>
  <div id="app">
    <Game></Game>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import Game from '@/components/Game.vue';

export default {
  name: 'App',
  components: {
    Game,
  },
}
</script>
