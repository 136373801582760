export default {
  data: [
    { id: 1, name: "Alabama", electors: 9, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 2, name: "Alaska", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 3, name: "Arizona", electors: 11, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 4, name: "Arkansas", electors: 6, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 5, name: "California", electors: 55, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 6, name: "Colorado", electors: 9, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 7, name: "Connecticut", electors: 7, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 8, name: "D.C.", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 9, name: "Delaware", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 10, name: "Florida", electors: 29, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 11, name: "Georgia", electors: 16, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 12, name: "Hawaii", electors: 4, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 13, name: "Idaho", electors: 4, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 14, name: "Illinois", electors: 20, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 15, name: "Indiana", electors: 11, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 16, name: "Iowa", electors: 6, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 17, name: "Kansas", electors: 6, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 18, name: "Kentucky", electors: 8, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 19, name: "Louisiana", electors: 8, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 20, name: "Maine", electors: 4, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 21, name: "Maryland", electors: 10, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 22, name: "Massachusetts", electors: 11, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 23, name: "Michigan", electors: 16, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 24, name: "Minnesota", electors: 10, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 25, name: "Mississippi", electors: 6, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 26, name: "Missouri", electors: 10, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 27, name: "Montana", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 28, name: "Nebraska", electors: 5, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 29, name: "Nevada", electors: 6, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 30, name: "New Hampshire", electors: 4, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 31, name: "New Jersey", electors: 14, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 32, name: "New Mexico", electors: 5, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 33, name: "New York", electors: 29, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 34, name: "North Carolina", electors: 15, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 35, name: "North Dakota", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 36, name: "Ohio", electors: 18, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 37, name: "Oklahoma", electors: 7, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 38, name: "Oregon", electors: 7, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 39, name: "Pennsylvania", electors: 20, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 40, name: "Rhode Island", electors: 4, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 41, name: "South Carolina", electors: 9, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 42, name: "South Dakota", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 43, name: "Tennessee", electors: 11, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 44, name: "Texas", electors: 38, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 45, name: "Utah", electors: 6, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 46, name: "Vermont", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 47, name: "Virginia", electors: 13, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 48, name: "Washington", electors: 12, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 49, name: "West Virginia", electors: 5, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 50, name: "Wisconsin", electors: 10, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
    { id: 51, name: "Wyoming", electors: 3, complete: false, won: false, mine: 0.0, theirs: 0.0, state: 'waiting' },
  ]
};