<template>
  <div id="done" class="text-center mt-4">
    <h1 v-if="mine > theirs" class="text-uppercase text-success">You've won the election!</h1>
    <h1 v-if="mine < theirs" class="text-uppercase text-danger">You've lost the election!</h1>
    <p v-if="mine < theirs">{{ lostMessage }}</p>
    <p class="lead">Refresh the page to start again</p>
    <div class="card mt-4">
      <div class="card-body">
        <h5 class="card-title">Game Stats</h5>
        <p>
          Highest percentage of state votes counted: {{ outcomes.highestPercentage.toFixed(2) }}%
        </p>
        <p>
          Number of lawsuits filed: {{ outcomes.lawsuits }}
        </p>
        <h6>States Won</h6>
        <ul class="list-group list-group-flush">
          <li v-for="(state, idx) in outcomes.statesWon" :key="idx" class="list-group-item">{{ state }}</li>
        </ul>
        <p class="mt-4">
          Not all of them? Who cares! It's over!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Done',
  props: ['mine', 'theirs', 'outcomes'],
  data() {
    return {
      lostMessage: '',
    };
  },
  mounted() {
    if (this.mine > this.theirs) {
      this.$confetti.start();
    }

    const messages = [
      "Try again in four years",
      "Next time, try calling the secretary of state",
      "Have you tried soliciting foreign influence?",
      "Try starting your own social media platform to rally your base",
    ];
    const idx = Math.floor(Math.random() * messages.length);
    this.lostMessage = messages[idx];
  },
};
</script>
