<template>
  <div id="game">
    <div v-if="!done" class="container">
      <div class="row mt-2">
        <div class="col-6 text-center">
          <h4>Your Electors: {{ myElectors }}</h4>
        </div>
        <div class="col-6 text-center">
          <h4>Their Electors: {{ theirElectors }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" :style="{ width: myPercent }"></div>
            <div class="progress-bar bg-warning" role="progressbar" :style="{ width: theirPercent }"></div>
          </div>
        </div>
      </div>
      <Notice v-if="selected === null"></Notice>
      <State v-if="selected !== null" :state="selected" @win="win" @lose="lose"></State>
      <ul class="list-group mt-4">
        <a
          href="#"
          @click.prevent="select(state)"
          class="list-group-item list-group-item-action"
          v-for="state in states"
          :key="state.id"
        >
          {{ state.name }}
          <span class="badge rounded-pill bg-success">{{ state.electors }}</span>
        </a>
      </ul>
    </div>
    <div v-if="done" class="container">
      <Done :mine="myElectors" :theirs="theirElectors" :outcomes="outcomes"></Done>
    </div>
  </div>
</template>

<script>
import states from '@/states.js';
import Notice from '@/components/Notice.vue';
import State from '@/components/State.vue';
import Done from '@/components/Done.vue';

export default {
  name: 'Game',
  components: {
    Notice,
    State,
    Done,
  },
  data() {
    return {
      done: false,
      myElectors: 0,
      theirElectors: 0,
      selected: null,
      states: states.data,
      outcomes: {
        statesWon: [],
        lawsuits: 0,
        highestPercentage: 0,
      },
    };
  },
  computed: {
    myPercent() {
      return ((this.myElectors / 538.0) * 100.0).toFixed(2) + '%';
    },
    theirPercent() {
      return ((this.theirElectors / 538.0) * 100.0).toFixed(2) + '%';
    },
  },
  methods: {
    select(state) {
      this.selected = state;
      window.scrollTo(0, 0);
    },
    win(stats) {
      this.myElectors += this.selected.electors;
      this.addStats(stats);
      this.removeState();
      this.evalWinning();
    },
    lose(stats) {
      this.theirElectors += this.selected.electors;
      this.addStats(stats);
      this.removeState();
      this.evalWinning();
    },
    addStats(stats) {
      this.outcomes.statesWon.push(stats.name);
      if (stats.sue) {
        this.outcomes.lawsuits++;
      }
      if (stats.percent > this.outcomes.highestPercentage) {
        this.outcomes.highestPercentage = stats.percent;
      }
    }, 
    removeState() {
      for (let i = 0; i < 51; i++) {
        if (this.states[i].id === this.selected.id) {
          this.states.splice(i, 1);
          break;
        }
      }
      this.selected = null;
    },
    evalWinning() {
      if (this.myElectors >= 270 || this.theirElectors >= 270) {
        this.done = true;
      }
    },
  },
};
</script>