<template>
  <div id="state">
    <div class="card mt-4">
      <div class="card-body">
        <h5 class="card-title">
          {{ state.name }}
          <span class="badge rounded-pill bg-secondary">{{ state.electors }}</span>
          <span v-if="state.state !== 'waiting'" class="mx-2">
            {{ (state.mine + state.theirs).toFixed(2) }}% Reporting
          </span>
        </h5>
        <div class="progress">
          <div class="progress-bar bg-success" role="progress" :style="{ width: mine }"></div>
        </div>
        <p class="lead">Your Party</p>
        <div class="progress mt-2">
          <div class="progress-bar bg-warning" role="progress" :style="{ width: theirs }"></div>
        </div>
        <p class="lead">Their Party</p>
        <a
          v-if="state.state === 'waiting'"
          class="btn btn-primary"
          @click.prevent="start"
        >
          Start Counting
        </a>
        <p v-if="state.state === 'waiting'" class="mt-4">
          Click "Start Counting" to begin counting votes. Watch the meters that show the percentage of
          votes for your party vs. their party. When your party is ahead, click "Stop Counting Votes!"
          so you can win! Pay attention to the percentage reporting, if it gets to 100%, there are no
          more votes to count and the winner will be based on the actual democratic process! You might
          not win if that happens!
        </p>
        <p v-if="state.state === 'starting'" class="lead">
          Starting in {{ count }}...
        </p>
        <a
          v-if="state.state === 'running'"
          class="btn btn-danger"
          @click.prevent="stop"
        >
          Stop Counting Votes!
        </a>
        <div v-if="state.state === 'done' && state.mine > state.theirs">
          <h4 class="text-primary">YOU WON!!!</h4>
          <a href="#" class="btn btn-primary" @click.prevent="finish('win', false)">Accept Victory!</a>
        </div>
        <div v-if="state.state === 'done' && state.mine < state.theirs">
          <h4 class="text-danger">YOU LOST!!!</h4>
          <a href="#" class="btn btn-danger" @click.prevent="finish('lose', false)">Concede Defeat!</a>
          <a href="#" class="btn btn-primary mx-2" @click.prevent="finish('win', true)">Sue The State!</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'State',
  props: ['state'],
  data() {
    return {
      count: 3,
      timeout: null,
    };
  },
  computed: {
    mine() {
      return this.state.mine.toFixed(2) + '%';
    },
    theirs() {
      return this.state.theirs.toFixed(2) + '%';
    },
  },
  methods: {
    start() {
      this.state.state = 'starting';
      this.count = 3;
      this.countdown();
    },
    countdown() {
      if (this.count > 0) {
        this.timeout = setTimeout(() => {
          this.count--;
          this.countdown();
        }, 1000);
      } else {
        this.timeout = null;
        this.state.state = 'running';
        this.run();
      }
    },
    run() {
      this.timeout = setTimeout(() => {
        this.state.mine += Math.random();
        this.state.theirs += Math.random();
        if (this.state.mine > 100.0) {
          this.state.mine = 100.0;
        }
        if (this.state.theirs > 100.0) {
          this.state.theirs = 100.0;
        }
        if (this.state.mine + this.state.theirs >= 100.0) {
          if (this.state.mine + this.state.theirs > 100.0) {
            this.state.theirs -= (this.state.mine + this.state.theirs) - 100.0;
          }
          this.stop();
          return;
        }
        this.run();
      }, 100);
    },
    stop() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.state.state = 'done';
      this.complete = true;
    },
    finish(winLose, sueState) {
      this.$emit(winLose, {
        name: this.state.name,
        sue: sueState,
        percent: this.state.mine + this.state.theirs,
      });
    },
  },
};
</script>
